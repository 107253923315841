import axios from 'axios'
import React, { useContext, useState } from 'react'
import { AppContext } from '../App'
import { CopyAll } from '@mui/icons-material'
var sha256 = require('js-sha256');

export default function CheckBifastRefId() {
  const [referenceId, setReferenceId] = useState("")
  const [transaction, setTransaction] = useState({ currentAccount: [], proposedRefund: [], refunded: [] })
  const [error, setError] = useState("")
  const context = useContext(AppContext)

  function getTableData(table) {
    let text = '';
    const rows = table.querySelectorAll('tr');

    // Iterate through rows and cells
    for (const row of rows) {
      const cells = row.querySelectorAll('th, td');
      for (const cell of cells) {
        text += cell.textContent.trim() + '\t'; // Use tabs for separation
      }
      text += '\n'; // Add newline after each row
    }

    return text;
  }

  function copytable(el) {
    const table = document.getElementById('table');
    const text = getTableData(table)
    navigator.clipboard.writeText(text).then(() => {
      alert('Table data copied to clipboard!');
    })
      .catch(err => {
        alert('Failed to copy table data');
        // Handle clipboard permission errors or other issues (optional)
      });
  }

  
  async function fetchCheckBifastRefId(event) {
    event.preventDefault()
    const timestamp = `${new Date().getTime()}`
    const headers = {
      "Authorization": "Basic ZGFzaDokJSREJEFTSA==",
      "x-signature": sha256(`${timestamp}:${"p1Ro"}`),
      "x-timestamp": timestamp
    }
    try {
      setError("")
      setTransaction(null)

      const url = `https://us-central1-doc-basic.cloudfunctions.net/dashboard2gen/v1/track-current-account?referenceId=${referenceId}`
      const transactionResponse = await axios.get(url, {
        headers
      })
      setTransaction(transactionResponse.data.data)
    } catch (error) {
      setError("Status : Transaksi Tidak ditemukan pada sistem")
    }
  }

  return (
    <div style={{ padding: "20px" }}>
      <h2>Check Based Ref ID</h2>
      <div>
        <form onSubmit={fetchCheckBifastRefId}>
          <label>Reference ID</label>
          <div style={{ display: "flex" }}>
            <input
              className=''
              name="referenceId"
              type='text'
              placeholder='Input Reference Id'
              value={referenceId}
              onChange={(event) => setReferenceId(event.target.value)}
            />
            <button style={{ padding: "0px 10px 0px 10px", marginLeft: "10px" }} type='button' onClick={e => {
              setTransaction(null)
              setReferenceId("")
            }}>Clear</button>
            <button style={{ padding: "0px 10px 0px 10px", marginLeft: "10px" }} type='submit'>Submit</button>
          </div>
        </form>
      </div>
      <div style={{ marginTop: "40px", display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
        {error && (
          <p>{error}</p>
        )}
        {transaction && (
          <>
            <button onClick={copytable} style={{ marginBottom: "10px", display: "flex", justifyContent: "center", padding: "5px" }}>
              <CopyAll></CopyAll>
              Copy
            </button>
            <table id='table' style={{ backgroundColor: "#cecece", padding: "15px", width: "100%" }}>
              <thead></thead>
              <tbody>
                {/* <tr>
                  <td>Reference ID</td>
                  <td>: </td>
                  <td>{transaction.currentAccount[0].data.referenceId}</td>
                </tr> */}
                {/* Tidak Di Temukan */}
                {transaction.currentAccount.length < 1 && transaction.proposedRefund.length < 1 && transaction.refunded.length < 1 && (
                  <>
                    <tr>
                      <td>Status</td>
                      <td>: </td>
                      <td> <span style={{ backgroundColor: "red", color: "white", padding: "3px 10px", borderRadius: "20px" }}> Transaksi Tidak ditemukan pada sistem</span> </td>
                    </tr>
                  </>
                )}
                {/* SUKSES di TRANSFER */}
                {transaction.currentAccount[0]?.data?.attachment?.callback && transaction.proposedRefund.length < 1 && transaction.refunded.length < 1 && (
                  <>
                  {console.log("masuk Sukses Transfer -1 ")}
                    <tr>
                      <td>Status</td>
                      <td>: </td>
                      <td> <span style={{ backgroundColor: "green", color: "white", padding: "3px 10px", borderRadius: "20px" }}> Sukses di Transfer</span> </td>
                    </tr>
                    <tr>
                      <td>Tanggal di transfer</td>
                      <td>: </td>
                      <td>{transaction.currentAccount[0].data.attachment.callback.createdTime}</td>
                    </tr>
                    <tr>
                      <td>No Rekening Tujuan</td>
                      <td>: </td>
                      <td>{transaction.currentAccount[0].data.attachment.trigger.data.req.beneficiaryAccountNumber}</td>
                    </tr>
                    <tr>
                      <td>Kode Bank</td>
                      <td>: </td>
                      <td>{transaction.currentAccount[0].data.attachment.trigger.data.req.beneficiaryBankCode}</td>
                    </tr>
                    <tr>
                      <td>Reference No</td>
                      <td>: </td>
                      <td>{transaction.currentAccount[0].data.attachment.callback.originalReferenceNo}</td>
                    </tr>
                    <tr>
                      <td>Serial Transaksi</td>
                      <td>: </td>
                      <td>{transaction.currentAccount[0].data.attachment.callback.originalPartnerReferenceNo}</td>
                    </tr>
                  </>
                )}
                {transaction.currentAccount.length > 0 && !transaction?.currentAccount[0]?.data?.attachment?.callback && transaction.proposedRefund.length < 1 && transaction.refunded.length < 1 && (
                  <>
                  {console.log("masuk Sukses Transfer -2 ")}
                    <tr>
                      <td>Status</td>
                      <td>: </td>
                      <td> <span style={{ backgroundColor: "green", color: "white", padding: "3px 10px", borderRadius: "20px" }}> Sukses di Transfer</span> </td>
                    </tr>
                    {transaction?.currentAccount[0]?.data?.attachment?.trigger && (
                      <>
                       <tr>
                       <td>Tanggal di transfer</td>
                       <td>: </td>
                       <td>{transaction.currentAccount[0].data?.attachment?.trigger?.data?.res?.transactionDate}</td>
                     </tr>
                     <tr>
                       <td>No Rekening Tujuan</td>
                       <td>: </td>
                       <td>{transaction.currentAccount[0].data?.attachment?.trigger?.data?.req?.beneficiaryAccountNumber}</td>
                     </tr>
                     <tr>
                       <td>Kode Bank</td>
                       <td>: </td>
                       <td>{transaction.currentAccount[0].data?.attachment?.trigger?.data?.req?.beneficiaryBankCode}</td>
                     </tr>
                     <tr>
                       <td>Reference No</td>
                       <td>: </td>
                       <td>{transaction.currentAccount[0].data?.attachment?.trigger?.data?.res?.referenceNumber}</td>
                     </tr>
                     <tr>
                       <td>Serial Transaksi</td>
                       <td>: </td>
                       <td>{transaction.currentAccount[0].data?.attachment?.trigger?.data?.res?.partnerReferenceNo}</td>
                     </tr>
                    </>
                    )}
                    {!transaction?.currentAccount[0]?.data?.attachment?.callback && (
                      <>
                       <tr>
                       <td>Tanggal di transfer</td>
                       <td>: </td>
                       <td>{transaction.currentAccount[0].metadata?.createdDate}</td>
                     </tr>
                     <tr>
                       <td>No Rekening Tujuan</td>
                       <td>: </td>
                       <td>{transaction.currentAccount[0].data?.attachment?.beneficiaryAccountNumber}</td>
                     </tr>
                     <tr>
                       <td>Kode Bank</td>
                       <td>: </td>
                       <td>{transaction.currentAccount[0].data?.attachment?.beneficiaryBankCode}</td>
                     </tr>
                     <tr>
                       <td>Serial Transaksi</td>
                       <td>: </td>
                       <td>{transaction.currentAccount[0].data?.attachment?.partnerReferenceNo}</td>
                     </tr>
                    </>
                    )}
                  </>
                )}
                {/* Dalam Proses Refund */}
                {!transaction.currentAccount[0]?.data?.attachment?.callback && transaction.proposedRefund.length > 0 && transaction.refunded.length < 1 && (
                  <>
                  {console.log("masuk Dalam proses refund -1 ")}
                    <tr>
                      <td>Status</td>
                      <td>: </td>
                      <td>
                        {new Date(transaction.proposedRefund[0]?.metadata?.createdDate).getTime() >= new Date("Sep 04 2024 23:59:59").getTime() ? (
                          <span style={{ backgroundColor: "orange", color: "white", padding: "3px 10px", borderRadius: "20px" }}>
                            Transaksi Pending
                          </span>
                        ) : (
                          // <span style={{ backgroundColor: "orange", color: "white", padding: "3px 10px", borderRadius: "20px" }}>
                          //   Dalam Proses Refund
                          // </span>
                          <span style={{ backgroundColor: "orange", color: "white", padding: "3px 10px", borderRadius: "20px" }}>
                          Transaksi Pending
                        </span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Tanggal di request</td>
                      <td>: </td>
                      <td>{new Date(transaction.proposedRefund[0].metadata.createdDate).toISOString().slice(0, 10) + ' ' + new Date(transaction.proposedRefund[0].metadata.createdDate).toISOString().slice(11, 19)}</td>
                    </tr>
                    <tr>
                      <td>No Rekening Tujuan</td>
                      <td>: </td>
                      <td>{transaction.currentAccount[0]?.data?.attachment?.beneficiaryAccountNumber}</td>
                    </tr>
                    <tr>
                      <td>Kode Bank</td>
                      <td>: </td>
                      <td>{transaction.currentAccount[0]?.data?.attachment?.beneficiaryBankCode}</td>
                    </tr>
                    <tr>
                      <td>TX</td>
                      <td>: </td>
                      <td>{transaction.currentAccount[0]?.data?.TX}</td>
                    </tr>
                  </>
                )}
                {transaction.currentAccount[0]?.data?.attachment?.callback && transaction.proposedRefund.length > 0 && transaction.refunded.length < 1 && (
                  <>
                  {console.log("masuk Dalam proses refund -2 ")}
                    <tr>
                      <td>Status</td>
                      <td>: </td>
                      <td>
                        {/* <span style={{ backgroundColor: "orange", color: "white", padding: "3px 10px", borderRadius: "20px" }}> Dalam Proses Refund</span> */}
                        <span style={{ backgroundColor: "orange", color: "white", padding: "3px 10px", borderRadius: "20px" }}>
                          Transaksi Pending
                        </span>
                        </td>
                    </tr>
                    <tr>
                      <td>Tanggal di request</td>
                      <td>: </td>
                      <td>{transaction.currentAccount[0].data.attachment.callback.createdTime}</td>
                    </tr>
                    <tr>
                      <td>No Rekening Tujuan</td>
                      <td>: </td>
                      <td>{transaction.currentAccount[0].data.attachment.trigger.data.req.beneficiaryAccountNumber}</td>
                    </tr>
                    <tr>
                      <td>Kode Bank</td>
                      <td>: </td>
                      <td>{transaction.currentAccount[0].data.attachment.trigger.data.req.beneficiaryBankCode}</td>
                    </tr>
                    <tr>
                      <td>TX</td>
                      <td>: </td>
                      <td>{transaction.currentAccount[0].data.TX}</td>
                    </tr>
                  </>
                )}
                {/* Sudah di Refund */}
                {transaction.refunded.length > 0 && transaction.proposedRefund.length < 1 && (
                  <>
                  {console.log("masuk sudah refund -1 ")}
                    <tr>
                      <td>Status</td>
                      <td>: </td>
                      <td><span style={{ backgroundColor: "blue", color: "white", padding: "3px 10px", borderRadius: "20px" }}> Sudah di Refund</span></td>
                    </tr>
                    {!transaction.currentAccount[0].data.attachment.callback && (
                      <>
                        <tr>
                          <td>Tanggal Refund</td>
                          <td>: </td>
                          <td>{new Date(transaction.refunded[0].metadata.createdDate).toISOString().slice(0, 10) + ' ' + new Date(transaction.refunded[0].metadata.createdDate).toISOString().slice(11, 19)}</td>
                        </tr>
                        <tr>
                          <td>No Rekening Tujuan</td>
                          <td>: </td>
                          <td>{transaction.currentAccount[0].data.attachment.beneficiaryAccountNumber}</td>
                        </tr>
                        <tr>
                          <td>Kode Bank</td>
                          <td>: </td>
                          <td>{transaction.currentAccount[0].data.attachment.beneficiaryBankCode}</td>
                        </tr>
                        <tr>
                          <td>TX</td>
                          <td>: </td>
                          <td>{transaction.currentAccount[0].data.TX}</td>
                        </tr>
                      </>
                    )}
                    {transaction.currentAccount[0].data.attachment.callback && (
                      <>
                        <tr>
                          <td>Tanggal Refund</td>
                          <td>: </td>
                          <td>{transaction.currentAccount[0].data.attachment.callback.createdTime}</td>
                        </tr>
                        <tr>
                          <td>No Rekening Tujuan</td>
                          <td>: </td>
                          <td>{transaction.currentAccount[0].data.attachment.trigger.data.req.beneficiaryAccountNumber}</td>
                        </tr>
                        <tr>
                          <td>Kode Bank</td>
                          <td>: </td>
                          <td>{transaction.currentAccount[0].data.attachment.trigger.data.req.beneficiaryBankCode}</td>
                        </tr>
                        <tr>
                          <td>TX</td>
                          <td>: </td>
                          <td>{transaction.currentAccount[0].data.TX}</td>
                        </tr>
                      </>
                    )}
                  </>
                )}
              </tbody>
              <tfoot></tfoot>
            </table>
          </>
        )}


      </div>
    </div>
  )
}
